
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import OutletsList from '../../features/outlets/components/OutletsList';
import Page from '../../features/layout/components/Page';
import NavbarOutletSearch from '../../features/layout/components/main/navBar/NavbarOutletSearch';
import Container from '../../features/layout/components/container/Container';
import ClientAuthContainer from '../../features/auth/components/ClientAuthContainer';
import OutletsListPageSkeleton from '../../features/outlets/components/OutletsListPageSkeleton';
import isAppWhiteLabeled from '../../features/app/utils/isAppWhiteLabeled';
import CompanySignUpURLFilter from '../../features/company/components/CompanySignUpURLFilter';
import { clientContextFetchPendingSelector, clientContextIsCompanyUserSelector, } from '../../features/clientContext/store/clientContextSelectors';
import useAppSelector from '../../store/hooks/useAppSelector';
import CompanyDashboardDesktop from '../../features/company/components/CompanyDashboardDesktop';
import CompanyDashboardMobile from '../../features/company/components/CompanyDashboardMobile';
import useDeviceSize from '../../features/app/hooks/useDeviceSize';
import useAppDispatch from '../../store/hooks/useAppDispatch';
import { validatePreSelectedOutlet } from '../../features/outlet/store/outletThunks';
import localStorageUtils from '../../features/app/utils/localStorageUtils';
import RestaurantSelectionModal from '../../features/outlets/components/selectedRestaurant/RestaurantSelectionModal';
import styles from './index.module.scss';
function OutletsListPage() {
    const { t } = useTranslation('outlets');
    const dispatch = useAppDispatch();
    const isWhiteLabeled = isAppWhiteLabeled();
    const { isMobile } = useDeviceSize();
    const isCompanyUser = useAppSelector(clientContextIsCompanyUserSelector);
    const isClientContextLoading = useAppSelector(clientContextFetchPendingSelector);
    const isCompanyDashboard = !!isCompanyUser && isWhiteLabeled;
    useEffect(() => {
        async function validateStoredOutletId() {
            const outletId = localStorageUtils.getItem<string>('preSelectedOutletId');
            if (outletId) {
                try {
                    await dispatch(validatePreSelectedOutlet({ outletId })).unwrap();
                }
                catch (error) {
                    localStorageUtils.removeItem('preSelectedOutletId');
                }
            }
        }
        if (isWhiteLabeled) {
            validateStoredOutletId();
        }
    }, [dispatch, isWhiteLabeled]);
    return (<Page title={!isWhiteLabeled ? t('title') : undefined} description={t('description')}>
      <ClientAuthContainer skeleton={<OutletsListPageSkeleton />}>
        {isWhiteLabeled && <CompanySignUpURLFilter />}
        {!isClientContextLoading && !isCompanyDashboard && (<Container className={styles.locationSearchContainer}>
            <NavbarOutletSearch isInNavBar={false}/>
          </Container>)}
        {isClientContextLoading && <OutletsListPageSkeleton />}
        {!isClientContextLoading &&
            (isCompanyDashboard ? (<>
              {!isMobile && <CompanyDashboardDesktop />}
              {isMobile && <CompanyDashboardMobile />}
              <RestaurantSelectionModal />
            </>) : (<OutletsList />))}
      </ClientAuthContainer>
    </Page>);
}
export default OutletsListPage;

    async function __Next_Translate__getStaticProps__191e51fc52d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/outlets/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191e51fc52d__ as getStaticProps }
  